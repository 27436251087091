import "./V002.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  EventV002Schema,
  GeneralForListSchema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  fetchFcFacilities,
  fetchGeneralsForList,
  fetchV002,
  putEventDetailStatus,
  stopEventReservation,
} from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { COLOR_RED, DAYS_OF_WEEK, HEADQUARTER_USER, LIST_EVENT_DETAIL_STATUS, PARENT } from "../../constants";
import ConfirmModal from "../../components/ConfirmModal";
import User, { SIZES } from "../../components/Pict/User"
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import { FormCheck } from "react-bootstrap";
import { EVENT_DETAIL_STATUS, initialEventV002Model } from "../../models/event.model";
import Chat from "../../components/Icon/Chat";
import Status, { STATUS_BLUE, STATUS_GRAY, STATUS_RED, StatusSchema } from "../../components/Status/Status";
import { C002Url, D002Url, G001Url, V003Url, getForHistoryPush } from "../../helper/url.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { useAuth } from "../../helper/auth.helper";
import Pict from "../../components/Pict/Pict";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iLocation {
  event_id: number;
}

const V002 = () => {
  const { checkLoggedIn } = useAuth();
  const userInfo = useSelector(userInfoSelector);
  const maxWidth = 1000;
  const location = useLocation<iLocation>();
  const [eventId, setEventId] = useState(location.state.event_id);
  const authState = useSelector(authSelector);

  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<EventV002Schema>(initialEventV002Model());
  const history = useHistory();
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [statusList, setStatusList] = useState<GeneralForListSchema[]>([]);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showRegister, setShowRegister] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showStopReservation, setShowStopReservation] = useState(false);
  const [showRegisterButton, setShowRegisterButton] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const status_list: StatusSchema[] = [
    { num: EVENT_DETAIL_STATUS.RESERVED, class: STATUS_RED },
    { num: EVENT_DETAIL_STATUS.APPROVED, class: STATUS_BLUE },
    { num: EVENT_DETAIL_STATUS.CANCELED, class: STATUS_GRAY }
  ];
  const [reload, setReload] = useState(true);
  const [remainingNum, setRemainingNum] = useState(0);
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_EVENT_DETAIL_STATUS).then(res => {
        if (isMounted) {
          setStatusList(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (statusList.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, statusList.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then((res) => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_HEAD, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_HEAD, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchV002(authState, eventId).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, eventId, reload]);

  const clickRegister = () => {
    changeIsLoading();
    putEventDetailStatus(authState, checkedList.map(x => data.event_details[x].id), EVENT_DETAIL_STATUS.APPROVED).then((_) => {
      setShowRegister(false);
      setCheckedList([]);
      notify("承認しました。");
      changeIsLoading();
      setReload(true);
    }).catch((err) => {
      checkLoggedIn(err);
      setShowRegister(false);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const clickCancel = () => {
    changeIsLoading();
    putEventDetailStatus(authState, checkedList.map(x => data.event_details[x].id), EVENT_DETAIL_STATUS.CANCELED).then((_) => {
      setShowCancel(false);
      setCheckedList([]);
      notify("キャンセルしました。");
      changeIsLoading();
      setReload(true);
    }).catch((err) => {
      checkLoggedIn(err);
      setShowCancel(false);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const clickCheck = (index: number) => {
    if (checkedList.indexOf(index) == -1) {
      setCheckedList([...checkedList, index]);
    } else {
      const filteredList = checkedList.filter(x => x != index);
      setCheckedList(filteredList);
    }
  };

  useEffect(() => {
    setShowRegisterButton(false);
    setShowCancelButton(false);
    const remaining_num = data.limit - data.event_details.filter(x => x.status == EVENT_DETAIL_STATUS.APPROVED).length
    setRemainingNum(remaining_num);
    const reserved_num = checkedList.filter(x => data.event_details[x].status == EVENT_DETAIL_STATUS.RESERVED).length
    const approved_num = checkedList.filter(x => data.event_details[x].status == EVENT_DETAIL_STATUS.APPROVED).length
    if (reserved_num > 0 && approved_num == 0 && (remaining_num >= reserved_num || data.limit == 0)) {
      setShowRegisterButton(true);
    }
    if (reserved_num > 0 || approved_num > 0) {
      setShowCancelButton(true);
    }
  }, [checkedList, data]);

  const getSelectValue = (val: number | undefined, selectList: GeneralForListSchema[]) => {
    if (val == undefined) val = 0;
    if (selectList.filter(x => x.value == val).length == 0) return "";
    return selectList.filter(x => x.value == val)[0].label;
  };

  const clickStopReservation = () =>{
    changeIsLoading();
    stopEventReservation(authState, data.id).then((_) => {
      setReload(true);
      setShowStopReservation(false);
      notify("追加予約を不可としました。");
      changeIsLoading();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("変更に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  }
  

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="イベント参加管理" />
      <div className="plate">
        <div className="mx-2" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            <Pict
              size={SIZES.LL}
              url={data.image_url}
              func={() => window.open(data.image_url, "_blank")}
            />
            <div className="item">
              {userInfo?.user_type == HEADQUARTER_USER && (
                <>
                  <label style={{ fontSize: 16 }}>{getSelectValue(data.facility_id, fcForSelect)}</label>
                  <br />
                </>
              )}
              <label style={{ fontSize: 20 }}>{data.main_title}</label>
              {data.sub_title &&
                <label style={{ fontSize: 12 }}>{`　～${data.sub_title}～`}</label>
              }
              <br />
              <label>{`${data.dates}（${DAYS_OF_WEEK[new Date(data.dates).getDay()]}）${data.start_time}-${data.end_time}　`}</label>
              {data.limit == 0 ? (
                <label style={{ fontSize: 12 }}>{`上限人数：なし　予約数：${data.num}`}</label>
              ) : (
                remainingNum < 0 ? (
                  <>
                    <label style={{ fontSize: 12 }}>{`上限人数：${data.limit}　予約数：${data.num}`}</label>
                    <label style={{ fontSize: 12, color: COLOR_RED }}>{`　残数：${remainingNum}　予約可能数を下回っています。`}</label>
                  </>
                ) : (
                  <label style={{ fontSize: 12 }}>{`上限人数：${data.limit}　予約数：${data.num}　残数：${remainingNum}`}</label>
                )
              )}
              <br />
              <label style={{ fontSize: 12, whiteSpace: "pre-line" }}>{data.description}</label>
            </div>
          </div>
        </div>
        <div className="on_same_row justify mt-1" style={{ maxWidth: maxWidth }}>
          <CustomButton label="イベント変更" class={[CB_CLASS.RED]} onClick={() => history.push(getForHistoryPush(V003Url, [data.id, "V002"]))} />
          <div className="on_same_row">
            {showRegisterButton && <>
              <CustomButton label="承認" class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
              <ConfirmModal target="承認" show={showRegister} setShow={setShowRegister} func={clickRegister}
                confirmText={"承認してよろしいでしょうか。"}
              />
            </>}
            {showCancelButton && <>
              <CustomButton label="キャンセル" class={[CB_CLASS.RED]} onClick={() => setShowCancel(true)} />
              <ConfirmModal target="キャンセル" show={showCancel} setShow={setShowCancel} func={clickCancel}
                confirmText={"キャンセルしてよろしいでしょうか。"}
              />
            </>}
          </div>
          <CustomButton label="追加予約を不可とする" class={[CB_CLASS.RED]} onClick={() => setShowStopReservation(true)} disabled={data.num === 0 || data.num === data.limit} />
          <ConfirmModal target="追加予約不可" show={showStopReservation} setShow={setShowStopReservation} func={clickStopReservation}
            confirmText={"追加予約を不可としてよろしいでしょうか。"}
          />
        </div>
      </div>
      <PaginationTable
        tr_select={false}
        max_width={maxWidth}
        limit={10}
        headers={["", "", "お子様", "", "保護者", "", "予約状況"]}
        list={data.event_details.map((x, i) => (
          <tr key={i}>
            <td className="item" valign="middle" align="center">
              {x.status !== EVENT_DETAIL_STATUS.CANCELED &&
                <FormCheck
                  checked={checkedList.indexOf(i) > -1}
                  onChange={() => clickCheck(i)}
                />
              }
            </td>
            <td></td>
            <td className="item" valign="middle" align="center">
              <div className="on_same_row justify_center">
                <User
                  url={x?.child_profile_pic_url}
                  func={() => { history.push(getForHistoryPush(D002Url, [x.child_id])) }}
                />
                <div className="item">
                  {x.child_name}
                </div>
              </div>
            </td>
            <td></td>
            <td className="item" valign="middle" align="center">
              <div className="on_same_row justify_center">
                <User
                  url={x.parent_profile_pic_url}
                  func={() => { history.push(getForHistoryPush(C002Url, [x.parent_id])) }}
                />
                <div className="item">
                  {x.parent_name}
                </div>
                <Chat user_type={userInfo?.user_type} func={() => history.push(getForHistoryPush(G001Url, [x.parent_id, PARENT]))} />
              </div>
            </td>
            <td></td>
            <td className="item" valign="middle" align="center">
              <Status max_width={120} label_list={statusList} color_list={status_list} status={x.status} />
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default V002;
