import "./L001.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { authSelector } from "../../redux/selectors/auth.selector";
import { L001Schema } from "../../generated";
import L007 from "../L007/L007";
import L008 from "../L008/L008";
import { fetchL001, fetchL001Count } from "../../services/api.service";
import { L002Url, L004Url, getForHistoryPush } from "../../helper/url.helper";
import { HEADQUARTER_USER } from "../../constants.js";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import Title from "../../components/Title/Title";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import TextBox from "../../components/TextBox/TextBox";
import EquipmentPict from "../../components/Pict/EquipmentPict";
import { useAuth } from "../../helper/auth.helper";
import { windowOpen } from "../../helper/page.helper";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import Label from "../../components/Label/Label";

interface iSearch {
  serial: string;
  name: string;
};

const L001 = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<L001Schema[]>([]);
  const [getData, setGetData] = useState(true);
  const [showL007Modal, setShowL007Modal] = useState(false);
  const [showL008Modal, setShowL008Modal] = useState(false);
  const [equipmentId, setEquipmentId] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState<iSearch>({
    serial: "",
    name: "",
  });
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 9;

  const openL007 = (id: number) => {
    setShowL007Modal(true);
    setEquipmentId(id);
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchL001Count(search.serial, search.name, authState),
          fetchL001(search.serial, search.name, (currentPage - 1) * limitCount, limitCount, authState)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="備品一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            <CustomButton
              label={authState.userInfo?.user_type === HEADQUARTER_USER ? "受注一覧" : "発注一覧"}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(L002Url, [0]))}
            />
            <TextBox
              placeholder="品番"
              value={search.serial}
              onChange={(value: string) => {
                setSearch({ ...search, serial: value });
                setGetData(true);
              }}
            />
            <TextBox
              placeholder="備品名"
              value={search.name}
              onChange={(value: string) => {
                setSearch({ ...search, name: value });
                setGetData(true);
              }}
            />
          </div>
          <CustomButton
            label={authState.userInfo?.user_type === HEADQUARTER_USER ? "＋備品を追加" : "カート"}
            class={[CB_CLASS.RED]}
            onClick={authState.userInfo?.user_type === HEADQUARTER_USER ?
              () => history.push(getForHistoryPush(L004Url, [0]))
              : () => setShowL008Modal(true)}
          />
        </div>
        <div style={{ marginLeft: 160, fontSize: 13, color: "gray" }}>
          {"R: 療育備品　N: ノベルティ"}
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        tr_select={false}
        limit={limitCount}
        headers={["画像", "備品名", "品番", "大きさ", "色", "値段", "在庫数"]}
        list={data.map((x, i) => (
          <tr key={i} className={x.stock === 0 ? "" : "L001_tr"}
            onClick={() => authState.userInfo?.user_type === HEADQUARTER_USER ? windowOpen(getForHistoryPush(L004Url, [x.id])) : (x.stock !== 0 && openL007(x.id))}>
            <td className="item"><EquipmentPict url={x.image_url} /></td>
            <td className="L001_text_wrap" style={{ maxWidth: "100px" }}>{x.name}</td>
            <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.serial}</td>
            <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.size || "設定なし"}</td>
            <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.color || "設定なし"}</td>
            <td className="item">{x.price.toLocaleString() + " 円"}</td>
            <td className="item">{x.stock || (x.stock === 0 ? "在庫なし" : "受注後発注")}</td>
          </tr>
        ))}
      />
      {showL007Modal && <L007 showL007Modal={showL007Modal} setShowL007Modal={setShowL007Modal} equipmentId={equipmentId || 0} />}
      {showL008Modal && <L008 showL008Modal={showL008Modal} setShowL008Modal={setShowL008Modal} />}
    </>
  );
};

export default L001;
